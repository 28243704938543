import en from './languages/en'
import cn from './languages/cn'
import ta from './languages/ta'
import my from './languages/my'
import hi from './languages/hi'
import actionTypes from './actionTypes'

const initialState = en
const reducer = (state = initialState, action) => {
  if (!action) {
    return en
  }
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      switch (action.data.code) {
        case 'en':
          return en
        case 'cn':
          return cn
        case 'ta':
          return ta
        case 'my':
          return my
        case 'hi':
          return hi
        default:
          return state
      }
    default:
      return en
  }
}

export default reducer
