import CryptoJS from 'crypto-js'
import * as jose from 'jose'

const secretKey = 'iE7WRBPcxM98ANc+w4MCcD8ERjQT/GJ32AgkiPrgyJcSoTx2U5GkGZfzRwUdXq8o'
const jwtAlgo = 'HS256'
const expireIn = '60s'

/**
 * Returns Encrypted date
 * @param data Object - Object value which you want to encrypt
 * @returns encryptedDate - AES.encrypt
 */
export const encryptObjData = (data) => {
  const encryptedDate = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
  return encryptedDate
}

/**
 * Returns Encrypted data
 * @param data String - String value which you want to encrypt
 * @returns encryptedDate - AES.encrypt
 */
export const encryptData = (data) => {
  const encryptedDate = CryptoJS.AES.encrypt(data, secretKey).toString()
  return encryptedDate
}

/**
 * Returns Decrypted object data
 * @param ciphertext AES.encrypt - Encrypted object data
 * @returns decryptedData - Object
 */
export const decryptObjData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey)
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

/**
 * Returns Decrypted string data
 * @param ciphertext AES.encrypt - Encrypted string data
 * @returns decryptedData - String
 */
export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey)
  const originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText
}

/**
 * Checking login authentication
 * @returns true/false - True if Authentication done else False
 */
export const isAuthenticated = () => {
  const centerId = sessionStorage.getItem('ctrid')
  if (centerId) {
    return true
  } else {
    return false
  }
}

/**
 * Return Center detail
 * @returns Object - Center detail
 */
export const getCenterInfo = () => {
  const ciphertext = sessionStorage.getItem('ctrid')
  const centerUserInfo = decryptObjData(ciphertext)
  const centerInfo = centerUserInfo.center
  if (centerInfo) {
    return centerInfo
  } else {
    alert('Session is failed. Please re-login!')
    handleLock()
  }
}

/**
 * Return User detail
 * @returns Object - User detail
 */
export const getUserInfo = () => {
  const ciphertext = sessionStorage.getItem('ctrid')
  const centerUserInfo = decryptObjData(ciphertext)
  const userInfo = centerUserInfo.user
  if (userInfo) {
    return userInfo
  } else {
    alert('Session is failed. Please re-login!')
    handleLock()
  }
}

/**
 * Return Course detail
 * @returns Object - Course detail
 */
export const getCourseInfo = () => {
  const ciphertext = sessionStorage.getItem('ctrid')
  const centerUserInfo = decryptObjData(ciphertext)
  const courseInfo = centerUserInfo.course
  if (courseInfo) {
    return courseInfo
  } else {
    alert('Session is failed. Please re-login!')
    handleLock()
  }
}

/**
 * HANDLER - Remove session varibale and logout
 * @returns Home Page
 */
export const handleLock = () => {
  sessionStorage.clear()
  localStorage.clear()
  window.location.href = '/'
}

/**
 * Generate Session Key for API Validation
 * @param centerCiphertext AES.encrypt - Center information
 * @returns Status - Bool
 */
export const generateSessionKey = async (centerId) => {
  if (centerId) {
    // Creating session ID
    const secret = new TextEncoder().encode(secretKey)
    const alg = jwtAlgo
    const jwt = await new jose.SignJWT({ centerId: centerId })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setIssuer(`urn:${centerId}:issuer`)
      .setAudience(`urn:${centerId}:audience`)
      .setExpirationTime(expireIn)
      .sign(secret)

    return jwt
  } else {
    console.log('Failed to create session!')
    return false
  }
}

/**
 * Validate Session Key
 * @param centerId String - Center Id
 * @returns Status - Bool
 */
export const validateSessionKey = async (centerId) => {
  try {
    const sessionKey = sessionStorage.getItem('session')
    if (centerId && sessionKey) {
      const secret = new TextEncoder().encode(secretKey)
      const { payload, protectedHeader } = await jose.jwtVerify(sessionKey, secret, {
        issuer: `urn:${centerId}:issuer`,
        audience: `urn:${centerId}:audience`,
      })
      // Validate the Token value
      if (protectedHeader.alg === jwtAlgo && payload.centerId === centerId) {
        return true
      } else {
        return false
      }
    }
  } catch (error) {
    console.log(error.message)
    alert('Session is failed. Please re-login!')
    handleLock()
  }
}
