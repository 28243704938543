import {
  GET_DAILY_STATUS_SUCCESS,
  GET_STUDENT_LIST_SUCCESS,
  GET_COURSE_LIST_SUCCESS,
  GET_CENTER_DETAIL_SUCCESS,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_FEE_UNPAID_SUCCESS,
  GET_PROMO_LIST_SUCCESS,
  GET_PROMO_SEND_LIST_SUCCESS,
} from '../actions'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  dailyStatus: {},
  studentList: [],
  studentDetail: {},
  courseList: {},
  centerDetail: {},
  feeUnpaid: [],
  promoList: [],
  promoSendList: [],
}

const rootReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case GET_DAILY_STATUS_SUCCESS:
      return { ...state, dailyStatus: rest.dailyStatus }
    case GET_STUDENT_LIST_SUCCESS:
      return { ...state, studentList: rest.studentList }
    case GET_STUDENT_DETAIL_SUCCESS:
      return { ...state, studentDetail: rest.studentDetail }
    case GET_COURSE_LIST_SUCCESS:
      return { ...state, courseList: rest.courseList }
    case GET_CENTER_DETAIL_SUCCESS:
      return { ...state, centerDetail: rest.centerDetail }
    case GET_FEE_UNPAID_SUCCESS:
      return { ...state, feeUnpaid: rest.feeUnpaid }
    case GET_PROMO_LIST_SUCCESS:
      return { ...state, promoList: rest.promoList }
    case GET_PROMO_SEND_LIST_SUCCESS:
      return { ...state, promoSendList: rest.promoSendList }
    default:
      return state
  }
}

export default rootReducer
