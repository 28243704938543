import axios from 'axios'
import { generateSessionKey } from 'src/utils/Profile'
import getApiUrl from './getApiUrl'

const serverURL = getApiUrl()

const instance = axios.create({
  baseURL: `${serverURL}`,
  responseType: 'json',
})

const getDailyStatus = async (centerId, startDate, monthFee) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/daily-status/${centerId}/${startDate}/${monthFee}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getMeetStatus = async (centerId, startDate, endDate) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/meet-status/${centerId}/${startDate}/${endDate}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const postStudentAdd = async (centerId, studentId, studentData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/student-add/${centerId}/${studentId}`
    const response = await instance.post(apiUrl, studentData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putStudentUpdate = async (centerId, studentId, studentData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/student-add/${centerId}/${studentId}`
    const response = await instance.put(apiUrl, studentData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getStudentList = async (centerId, studentName) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/student-list/${centerId}/${studentName}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getStudentDetail = async (centerId, studentId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/student-detail/${centerId}/${studentId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getCourseList = async (centerId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/course-list/${centerId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const postCourseUpdate = async (centerId, courseData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/course-update/${centerId}/null`
    const response = await instance.post(apiUrl, courseData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putCourseUpdate = async (centerId, courseId, courseData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/course-update/${centerId}/${courseId}`
    const response = await instance.put(apiUrl, courseData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getUserDetail = async (user, password) => {
  try {
    const apiUrl = `v1/user-detail/${user}/${password}`
    const response = await instance.get(apiUrl)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putUserUpdate = async (centerId, email, role, userData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/user-update/${email}/${role}`
    const response = await instance.put(apiUrl, userData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getCenterDetail = async (centerId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/center-detail/${centerId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putCenterUpdate = async (centerId, centerData) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/center-update/${centerId}`
    const response = await instance.put(apiUrl, centerData, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getFeeDetail = async (centerId, studentId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/fee-detail/${centerId}/${studentId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putFeeUpdate = async (centerId, studentId, feeTransation, monthFee) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/fee-update/${centerId}/${studentId}/${monthFee}`
    const response = await instance.put(apiUrl, feeTransation, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getMeetDetail = async (centerId, studentId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/meet-detail/${centerId}/${studentId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putMeetUpdate = async (centerId, meetingId, meetingDetail) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/meet-update/${centerId}/${meetingId}`
    const response = await instance.put(apiUrl, meetingDetail, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getFeeUnpaid = async (centerId, monthFee) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/fee-unpaid/${centerId}/${monthFee}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const sendInvoice = async (centerId, formDate) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    }

    const apiUrl = `v1/share-invoice/${centerId}`
    const response = await instance.post(apiUrl, formDate, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const sendInvoiceWA = async (centerId, formDate) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    }

    const apiUrl = `v1/share-invoice-wa/${centerId}`
    const response = await instance.post(apiUrl, formDate, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const postFeeReminder = async (centerId, monthFee, data) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/fee-reminder/${centerId}/${monthFee}`
    const response = await instance.post(apiUrl, data, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getPromoList = async (centerId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/promo-list/${centerId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const putPromoUpdate = async (centerId, promoId, promoDetail) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/promo-update/${centerId}/${promoId}`
    const response = await instance.put(apiUrl, promoDetail, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const postPromoUpdate = async (centerId, promoId, promoDetail) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/promo-update/${centerId}/${promoId}`
    const response = await instance.post(apiUrl, promoDetail, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const getPromoSendList = async (centerId, promoId) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/promo-send-list/${centerId}/${promoId}`
    const response = await instance.get(apiUrl, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const postPromoSend = async (centerId, promoId, data) => {
  try {
    const token = await generateSessionKey(centerId)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const apiUrl = `v1/promo-send/${centerId}/${promoId}`
    const response = await instance.post(apiUrl, data, config)
    return response
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message)
      return error.response
    }
    alert(`ERROR: ${error.message}`)
    return error
  }
}

const rootAPIs = {
  getDailyStatus,
  getMeetStatus,
  postStudentAdd,
  putStudentUpdate,
  getStudentList,
  getStudentDetail,
  getCourseList,
  postCourseUpdate,
  putCourseUpdate,
  getUserDetail,
  putUserUpdate,
  getCenterDetail,
  putCenterUpdate,
  getFeeDetail,
  putFeeUpdate,
  getMeetDetail,
  putMeetUpdate,
  getFeeUnpaid,
  sendInvoice,
  sendInvoiceWA,
  postFeeReminder,
  getPromoList,
  getPromoSendList,
  putPromoUpdate,
  postPromoUpdate,
  postPromoSend,
}

export default rootAPIs
