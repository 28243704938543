import { element } from 'prop-types'
import React, { createContext, useReducer } from 'react'
import en from './languages/en'
import reducer from './reducer'

const I18n = createContext(en)
const I18nSwitch = createContext(null)

const I18nProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, en)
  return (
    <I18n.Provider value={state}>
      <I18nSwitch.Provider value={dispatch}>{children}</I18nSwitch.Provider>
    </I18n.Provider>
  )
}

I18nProvider.propTypes = {
  children: element.isRequired,
}

export { I18n, I18nSwitch, I18nProvider }
