import { put, takeLatest } from 'redux-saga/effects'
import {
  GET_CENTER_DETAIL,
  GET_CENTER_DETAIL_SUCCESS,
  GET_COURSE_LIST,
  GET_COURSE_LIST_SUCCESS,
  GET_DAILY_STATUS,
  GET_DAILY_STATUS_SUCCESS,
  GET_STUDENT_LIST,
  GET_STUDENT_LIST_SUCCESS,
  GET_STUDENT_DETAIL,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_FEE_UNPAID,
  GET_FEE_UNPAID_SUCCESS,
  GET_PROMO_LIST,
  GET_PROMO_LIST_SUCCESS,
  GET_PROMO_SEND_LIST_SUCCESS,
  GET_PROMO_SEND_LIST,
} from '../actions'

import rootAPIs from '../requests/APIs'

function* handleGetDailyStatus(payload) {
  try {
    const response = yield rootAPIs.getDailyStatus(
      payload.centerId,
      payload.startDate,
      payload.monthFee,
    )
    if (response.status !== 200) {
      throw new Error('Unable to get daily status')
    } else {
      yield put({ type: GET_DAILY_STATUS_SUCCESS, dailyStatus: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetStudentList(payload) {
  try {
    const response = yield rootAPIs.getStudentList(payload.centerId, payload.studentName)
    if (response.status !== 200) {
      throw new Error('Unable to get student list')
    } else {
      yield put({ type: GET_STUDENT_LIST_SUCCESS, studentList: response.data })
    }
  } catch (error) {
    console.error(error)
    alert(error)
  }
}

function* handleGetStudentDetail(payload) {
  try {
    const response = yield rootAPIs.getStudentDetail(payload.centerId, payload.studentId)
    if (response.status !== 200) {
      throw new Error('Unable to get daily status')
    } else {
      yield put({ type: GET_STUDENT_DETAIL_SUCCESS, studentDetail: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetCourseList(payload) {
  try {
    const response = yield rootAPIs.getCourseList(payload.centerId)
    if (response.status !== 200) {
      throw new Error('Unable to get course detail')
    } else {
      yield put({ type: GET_COURSE_LIST_SUCCESS, courseList: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetCenterDetail(payload) {
  try {
    const response = yield rootAPIs.getCenterDetail(payload.centerId)
    if (response.status !== 200) {
      throw new Error('Unable to get center details')
    } else {
      yield put({ type: GET_CENTER_DETAIL_SUCCESS, centerDetail: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetFeeUnpaid(payload) {
  try {
    const response = yield rootAPIs.getFeeUnpaid(payload.centerId, payload.monthFee)
    if (response.status !== 200) {
      throw new Error('Unable to get unpaid student list')
    } else {
      yield put({ type: GET_FEE_UNPAID_SUCCESS, feeUnpaid: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetPromoList(payload) {
  try {
    const response = yield rootAPIs.getPromoList(payload.centerId)
    if (response.status !== 200) {
      throw new Error('Unable to get promo list')
    } else {
      yield put({ type: GET_PROMO_LIST_SUCCESS, promoList: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* handleGetPromoSendList(payload) {
  try {
    const response = yield rootAPIs.getPromoSendList(payload.centerId, payload.promoId)
    if (response.status !== 200) {
      throw new Error('Unable to get promo send list')
    } else {
      yield put({ type: GET_PROMO_SEND_LIST_SUCCESS, promoSendList: response.data })
    }
  } catch (error) {
    console.error(error)
  }
}

function* rootSaga() {
  yield takeLatest(GET_DAILY_STATUS, handleGetDailyStatus)
  yield takeLatest(GET_STUDENT_LIST, handleGetStudentList)
  yield takeLatest(GET_STUDENT_DETAIL, handleGetStudentDetail)
  yield takeLatest(GET_COURSE_LIST, handleGetCourseList)
  yield takeLatest(GET_CENTER_DETAIL, handleGetCenterDetail)
  yield takeLatest(GET_FEE_UNPAID, handleGetFeeUnpaid)
  yield takeLatest(GET_PROMO_LIST, handleGetPromoList)
  yield takeLatest(GET_PROMO_SEND_LIST, handleGetPromoSendList)
}

export default rootSaga
