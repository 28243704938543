export const GET_DAILY_STATUS = 'GET_DAILY_STATUS'
export const GET_DAILY_STATUS_SUCCESS = 'GET_DAILY_STATUS_SUCCESS'
export const GET_STUDENT_LIST = 'GET_STUDENT_LIST'
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS'
export const GET_STUDENT_DETAIL = 'GET_STUDENT_DETAIL'
export const GET_STUDENT_DETAIL_SUCCESS = 'GET_STUDENT_DETAIL_SUCCESS'
export const GET_COURSE_LIST = 'GET_COURSE_LIST'
export const GET_COURSE_LIST_SUCCESS = 'GET_COURSE_LIST_SUCCESS'
export const GET_CENTER_DETAIL = 'GET_CENTER_DETAIL'
export const GET_CENTER_DETAIL_SUCCESS = 'GET_CENTER_DETAIL_SUCCESS'
export const GET_FEE_UNPAID = 'GET_FEE_UNPAID'
export const GET_FEE_UNPAID_SUCCESS = 'GET_FEE_UNPAID_SUCCESS'
export const GET_PROMO_LIST = 'GET_PROMO_LIST'
export const GET_PROMO_LIST_SUCCESS = 'GET_PROMO_LIST_SUCCESS'
export const GET_PROMO_SEND_LIST = 'GET_PROMO_SEND_LIST'
export const GET_PROMO_SEND_LIST_SUCCESS = 'GET_PROMO_SEND_LIST_SUCCESS'

export const getDailyStatus = (centerId, startDate, monthFee) => ({
  type: GET_DAILY_STATUS,
  centerId,
  startDate,
  monthFee,
})

export const getStudentList = (centerId, studentName) => ({
  type: GET_STUDENT_LIST,
  centerId,
  studentName,
})

export const getStudentDetail = (centerId, studentId) => ({
  type: GET_STUDENT_DETAIL,
  centerId,
  studentId,
})

export const getCourseList = (centerId) => ({
  type: GET_COURSE_LIST,
  centerId,
})

export const getCenterDetail = (centerId, role) => ({
  type: GET_CENTER_DETAIL,
  centerId,
  role,
})

export const getFeeUnpaid = (centerId, monthFee) => ({
  type: GET_FEE_UNPAID,
  centerId,
  monthFee,
})

export const getPromoList = (centerId) => ({
  type: GET_PROMO_LIST,
  centerId,
})

export const getPromoSendList = (centerId, promoId) => ({
  type: GET_PROMO_SEND_LIST,
  centerId,
  promoId,
})
