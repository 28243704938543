import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import { I18nProvider } from './i18n'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/home/login/Login'))

class App extends Component {
  render() {
    return (
      <I18nProvider>
        <HashRouter>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Routes>
              <Route exact path="/login/*" name="Login Page" element={<Login />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </I18nProvider>
    )
  }
}

export default App
